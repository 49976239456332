import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type GetSupportedNetworksType = {
  amount: number;
  balanceId: number;
  address: string;
  fireblocksId: string;
  addressId: boolean;
  isEnabled: boolean;
  name: string;
  code: string;
  id: number;
  iconUrl?: string;
};
export type GetSupportedNetworksArgs = {
  assetCode: string | number;
};
export type GetSupportedNetworksResponse = {
  getSupportedNetworks: DefaultApiResponse<GetSupportedNetworksType[]>;
};
export const getSupportedNetworksQuery: TypedDocumentNode<
  GetSupportedNetworksResponse,
  DefaultArg<GetSupportedNetworksArgs>
> = gql`
  query GetSupportedNetworks($record: GetSupportedNetworksRecord!) {
    getSupportedNetworks(record: $record) {
      amount
      balanceId
      address
      addressId
      fireblocksId
      isEnabled
      name
      code
      id
      iconUrl
    }
  }
`;
