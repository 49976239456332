import { Typography } from '@libs/components';
import { useTranslation } from 'src/hooks';
import { useTheme } from 'styled-components';
import {
  DescriptionSeparator,
  DescriptionsWrapper,
  DescriptionWrapper,
  ReferralOfferContainer,
  ReferralOfferTitleWrapper,
} from './styles';

type ReferralIntroduceOfferProps = {
  className?: string;
};
export const ReferralIntroduceOffer: React.FC<ReferralIntroduceOfferProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  return (
    <ReferralOfferContainer className={className}>
      <ReferralOfferTitleWrapper>
        <Typography fontFamily="primaryBold" type="bodyButton">
          {t('referral_promotion_offer_title')}
        </Typography>
      </ReferralOfferTitleWrapper>

      <DescriptionsWrapper>
        <DescriptionWrapper>
          <Typography type="titleTwo" fontFamily="primaryBold">
            {t('referral_rule_details_second_title')}
          </Typography>
          <Typography
            type="caption"
            fontFamily="primaryRegular"
            color={colors.text.secondary}
          >
            {t('referral_promotion_offer_description_first')}
          </Typography>
        </DescriptionWrapper>
        <DescriptionSeparator />
        <DescriptionWrapper>
          <Typography type="titleTwo" fontFamily="primaryBold">
            {t('referral_rule_details_first_title')}
          </Typography>
          <Typography
            type="caption"
            fontFamily="primaryRegular"
            color={colors.text.secondary}
          >
            {t('referral_promotion_offer_description_second')}
          </Typography>
        </DescriptionWrapper>
      </DescriptionsWrapper>
    </ReferralOfferContainer>
  );
};
