import styled from 'styled-components';

import { CircledPlusIcon, Radio, TransparentButton } from '@libs/components';

export const Card = styled(Radio)`
  border: none;
  border-radius: 0%;
  hover: {
    border: none;
  }
  height: 9.5rem;

  ${({ theme }) => theme.media.mobileL`
   height: inherit;
  `};
`;
export const CardWrapper = styled.div`
  display: flex;
`;

export const CardsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  overflow: hidden;
  ${Card} {
    border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  }
  ${CardWrapper} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  }
  ${CardWrapper}:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  }
  ${CardWrapper}:last-child {
    border-top-left-radius: unset;
    border-bottom: none;
  }
`;

export const AddCard = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5.6rem;
  width: 100%;
`;
export const CustomIcon = styled(CircledPlusIcon)`
  margin-right: 0.8rem;
`;

export const DeleteIconWrapper = styled.div`
  padding: 0 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.tablet`
    padding: 0 1.7rem;
  `};
`;
