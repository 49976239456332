import { useTheme } from 'styled-components';
import { TableCell, Typography } from '@libs/components';
import { convertExponentialToDecimal } from '@libs/helpers';

import { useTranslation } from 'src/hooks';

import {
  CustomAssetItem,
  CustomAssetItemDisabledText,
  CustomAssetItemImg,
  CustomAssetItemInfo,
  CustomAssetItemTextCont,
} from './style';

type CustomProps = {
  testId?: string;
  disabled?: boolean;
  disabledText?: string;
  text: string;
  description?: string;
  infoText?: string;
  icon?: string | JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  amount?: number;
  rate?: string;
};

const AssetItem: React.FC<CustomProps> = ({
  testId = 'assetItem',
  disabled,
  disabledText = '',
  text,
  description,
  infoText,
  onClick,
  icon,
  amount,
  rate,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled || typeof onClick !== 'function') return;
    onClick(e);
  };
  const image =
    typeof icon === 'string' ? <img src={icon} alt="tableIcon icon" /> : icon;
  const assetAmount =
    convertExponentialToDecimal(amount || 0).toString() + ' ' + description;
  const assetRate = t('common_asset_rate', { amount: String(rate) });

  return (
    <CustomAssetItem
      data-testid={testId}
      data-cy={testId}
      disabled={disabled}
      onClick={handleClick}
    >
      {icon && <CustomAssetItemImg>{image}</CustomAssetItemImg>}
      <CustomAssetItemTextCont>
        <Typography
          color={disabled ? colors.text.disabled : colors.text.default}
          fontFamily={'primaryBold'}
          type={'subHeadline'}
        >
          {text}
        </Typography>
        <Typography
          color={disabled ? colors.text.disabled : colors.text.secondary}
          fontFamily={'primaryMedium'}
          type={'caption'}
        >
          {description}
        </Typography>
      </CustomAssetItemTextCont>
      {!disabledText && (
        <CustomAssetItemInfo
          color={disabled ? colors.text.disabled : colors.text.secondary}
          fontFamily={'primaryMedium'}
          type={'caption'}
        >
          {infoText}
        </CustomAssetItemInfo>
      )}
      {disabledText && (
        <CustomAssetItemDisabledText
          color={colors.text.secondary}
          fontFamily={'primaryMedium'}
          type={'caption'}
        >
          {disabledText}
        </CustomAssetItemDisabledText>
      )}
      {assetAmount && assetRate && (
        <TableCell
          title={assetAmount}
          description={assetRate}
          justifyToLeft={false}
        />
      )}
    </CustomAssetItem>
  );
};
export default AssetItem;
