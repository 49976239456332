import { FC, useMemo } from 'react';

import { useTheme } from 'styled-components';

import {
  EmptyState,
  TransparentButton,
  Typography,
  useModal,
} from '@libs/components';
import { CurrencyType } from '@libs/types';
import { getEstimatedAmount } from '@libs/helpers';

import { BonusTotal } from 'src/gql';
import { ArrowRightIcon, DesertedIcon, TetherIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';

import { ReferralBalance } from '../referral-balance/ReferralBalance';
import { ReferralIntroduceOffer } from '../referral-introduce-offer/ReferralIntroduceOffer';

import { useReferralHistoryTable } from './useReferralHistory';

import {
  BalanceButtonsWrapper,
  CustomInfoCircleIcon,
  HistoryButton,
  IconWrapper,
  ReferralBalanceGlass,
  ReferralBalanceWrapper,
  RulesWrapper,
  TableWrapper,
  TotalClaimedBonusWrapper,
  FlexWrapper,
  ClaimedBonusUsdtImg,
  TextWrapper,
  CustomTable,
} from './styles';

type RefferalBalanceSummaryProps = {
  handleInfoIconClick: () => void;
  response?: BonusTotal;
};

export const ReferralBalanceSummary: FC<RefferalBalanceSummaryProps> = ({
  handleInfoIconClick,
  response,
}) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  const { Dialog, openModal } = useModal();

  const { data, columns, loading } = useReferralHistoryTable();

  const renderItem = useMemo(() => {
    if (loading) return null;

    if (data.length) {
      return <CustomTable columns={columns} data={data} />;
    } else {
      return (
        <EmptyState
          description={t('referral_empty_state_desc')}
          img={DesertedIcon}
        />
      );
    }
  }, [columns, data, loading, t]);

  return (
    <>
      <Dialog title={t('referral_history')}>
        <TableWrapper>
          <TotalClaimedBonusWrapper>
            <FlexWrapper>
              <ClaimedBonusUsdtImg src={TetherIcon} />
              <TextWrapper>
                <Typography type="caption" color={colors.text.secondary}>
                  {t('referral_total_claimed')}
                </Typography>
                <div>
                  <Typography type="largeTitle" fontFamily="primaryBold">
                    {getEstimatedAmount(response?.totalClaimedBonus)}{' '}
                  </Typography>
                  <Typography type="headline" fontFamily="primaryBold">
                    {CurrencyType.USDT}
                  </Typography>
                </div>
              </TextWrapper>
            </FlexWrapper>
          </TotalClaimedBonusWrapper>

          {renderItem}
        </TableWrapper>
      </Dialog>
      <ReferralBalanceWrapper>
        <BalanceButtonsWrapper>
          <RulesWrapper>
            <TransparentButton onClick={handleInfoIconClick}>
              <IconWrapper>
                <CustomInfoCircleIcon color={colors.text.interactive} />
              </IconWrapper>
            </TransparentButton>
            <Typography type="bodyButton" color={colors.text.interactive}>
              {t('referral_conditions')}
            </Typography>
          </RulesWrapper>
          <HistoryButton
            title={t('referral_history')}
            icon={<ArrowRightIcon color={colors.interactive.brandColor} />}
            iconPosition="right"
            buttonType="secondary"
            titleColor={colors.interactive.brandColor}
            onClick={openModal}
          />
        </BalanceButtonsWrapper>
        <ReferralBalanceGlass>
          <ReferralBalance />
        </ReferralBalanceGlass>
        <ReferralIntroduceOffer />
      </ReferralBalanceWrapper>
    </>
  );
};
