import { FC } from 'react';

import { useModal } from '@libs/components';
import { screenSizes } from '@libs/theme';

import { useDimensions, useTranslation } from 'src/hooks';
import { ReferralUsdtBanner } from 'src/assets';
import { BonusTotal } from 'src/gql';

import { ReferralRuleDetails } from '../referral-rule-details/ReferralRuleDetails';

import {
  BlueContainer,
  ReferralRulesGlass,
  CustomReferralRules,
  CustomReferralSummary,
  CustomReferralIntroduceOffer,
} from './styles';
import { ReferralBalanceSummary } from './ReferralBalanceSummary';

type ReferralBlueBlockProps = {
  hasInvitedUsers: boolean;
  response?: BonusTotal;
};

const ReferralBlueBlock: FC<ReferralBlueBlockProps> = ({
  hasInvitedUsers,
  response,
}) => {
  const { Dialog, openModal } = useModal();

  const { t } = useTranslation();

  const { width } = useDimensions();

  const titleType = width >= screenSizes.laptop ? 'largeTitle' : undefined;

  return (
    <>
      <Dialog title={t('referral_rules')}>
        <ReferralRuleDetails />
      </Dialog>
      <BlueContainer className="container">
        {hasInvitedUsers ? (
          <ReferralBalanceSummary
            handleInfoIconClick={openModal}
            response={response}
          />
        ) : (
          <ReferralRulesGlass>
            <CustomReferralRules
              handleSeeRulesClick={openModal}
              titleType={titleType}
              blueBlockTitle={t('referral_blue_block_title')}
              seeRules={t('referral_see_rules')}
              blueBlockFirstTitle={t('referral_rule_details_first_title')}
              blueBlockSecondTitle={t('referral_rule_details_second_title')}
              blueBlockFirstDesc={t('referral_rule_details_first_desc')}
              blueBlockSecondDesc={t('referral_rule_details_second_desc')}
              ReferralsUsdtBanner={ReferralUsdtBanner}
            />
          </ReferralRulesGlass>
        )}
      </BlueContainer>
      {hasInvitedUsers && (
        <>
          <CustomReferralIntroduceOffer />

          <CustomReferralSummary response={response} />
        </>
      )}
    </>
  );
};

export default ReferralBlueBlock;
