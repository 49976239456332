import { useMemo, useState } from 'react';

import { WalletTypeEnum } from '@libs/backend-common';
import { DefaultTabItem } from '@libs/components';
import { screenSizes } from '@libs/theme';

import { useDimensions, useTranslation } from 'src/hooks';

type AssetsTableTabItem = DefaultTabItem & {
  walletType?: WalletTypeEnum;
};

export const useAllAssetTabs = (hasActions?: boolean) => {
  const { t } = useTranslation();
  const { width } = useDimensions();

  const tabTitleSize = width < screenSizes.mobileL ? 'caption' : 'bodyButton';

  const tabItems: AssetsTableTabItem[] = useMemo(
    () =>
      hasActions
        ? [
            {
              id: 0,
              title: t('common_crypto'),
            },
            {
              id: 1,
              title: t('common_fiat'),
            },
          ]
        : [
            {
              id: 0,
              title: t('dashboard_my_assets'),
              titleSize: tabTitleSize,
              walletType: WalletTypeEnum.NON_ZERO_ASSETS,
            },
            {
              id: 1,
              title: t('common_favorite'),
              titleSize: tabTitleSize,
              walletType: WalletTypeEnum.FAVORITE_ASSETS,
            },
            {
              id: 2,
              title: t('dashboard_all_assets'),
              titleSize: tabTitleSize,
              walletType: WalletTypeEnum.ALL_ASSETS,
            },
          ],
    [hasActions, t, tabTitleSize]
  );

  const [activeTab, setActiveTab] = useState(tabItems[0].id);

  const activeTabObject = useMemo(
    () => tabItems.find((e) => e.id === activeTab) as AssetsTableTabItem,
    [activeTab, tabItems]
  );

  const title = hasActions
    ? t('dashboard_wallet_title')
    : activeTabObject.title;

  const walletType = hasActions
    ? WalletTypeEnum.ALL_ASSETS
    : (activeTabObject?.walletType as WalletTypeEnum);

  const isCrypto = hasActions ? activeTab === 0 : undefined;

  const shouldHaveExtraColumns = hasActions ? false : activeTab === 0;

  return {
    tabItems,
    setActiveTab,
    walletType,
    title,
    isCrypto,
    shouldHaveExtraColumns,
    activeTab,
  };
};
