import styled from 'styled-components';

export const ReferralOfferContainer = styled.div`
  margin: 0;
  margin-top: 1rem;
  flex-direction: column;
  display: none;
  ${({ theme }) => theme.media.laptop` 
        display: flex;
    `}
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const ReferralOfferTitleWrapper = styled.div`
  padding: 0.3rem 1.2rem;
  margin: 0.4rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.interactive.secondary};
  ${({ theme }) => theme.media.tablet`
  padding: 0.8rem  1.2rem;
`}
`;

export const DescriptionsWrapper = styled.div`
  display: flex;
  padding: 1.6rem;
  gap: 0.9rem;

  ${({ theme }) => theme.media.tablet`
  padding: 2rem 1.6rem 2rem;
  gap:2.4rem;
  
  `}
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DescriptionSeparator = styled.div`
  width: 0.1rem;
  height: '100%';
  background-color: ${({ theme }) => theme.colors.borders.default};
`;
