import { PageNotFoundContent } from '@libs/components';
import { PageNotFoundImage } from 'src/assets';
import { useTranslation } from 'src/hooks';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageNotFoundContent
        imageUrl={PageNotFoundImage}
        title={t('page_not_found_title')}
        description={t('page_not_fount_description')}
        buttonText={t('page_not_found_go_home')}
        shouldHaveMargin={false}
      />
    </div>
  );
};
export default PageNotFound;
