import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { Environment } from '@libs/types';
import { getJurisdictionBaseUrl } from '@libs/helpers';
import { JurisdictionsEnum } from '@libs/backend-common';

import { PublicRoute, PrivateRoute } from 'src/components';
import { APP_LINKS, AUTHENTICATOR_LINK } from 'src/constants';

import LoginPage from './login/LoginPage';
import Dashboard from './dashboard/Dashboard';
import TwoFaPage from './two-factor/TwoFaPage';
import RegisterPage from './register/RegisterPage';
import PageNotFound from './page-not-found/PageNotFound';

import VerifyEmailPage from './verify-email/VerifyEmailPage';
import ResetPasswordPage from './reset-password/ResetPasswordPage';
import PasswordRecoveryPage from './password-recovery/PasswordRecoveryPage';
import * as LazyLoaded from './lazy-loader';
import SwapTaskDetailsPage from './swap-task-details/SwapTaskDetailsPage';
import SwapDetailsPage from './swap-details/SwapDetailsPage';
import AssetDetailsPage from './asset-details/AssetDetailsPage';
import ActivateTwoFaPage from './activate-two-fa-page/ActivateTwoFaPage';
import { OSNavigator } from './operating-system-navigator/OperatingSystemNavigaor';

const { jurisdictionId } = getJurisdictionBaseUrl();
const isJurisdictionGe = jurisdictionId === JurisdictionsEnum.GE;

const getPrivateRoutesChildren = () => {
  const children = [
    { index: true, element: <LazyLoaded.DashboardContent /> },
    { path: 'feedback', element: <LazyLoaded.FeedbackPage /> },

    { path: 'kyc', element: <LazyLoaded.KycPage /> },

    ...(isJurisdictionGe
      ? [{ path: 'referrals', element: <LazyLoaded.ReferralsPage /> }]
      : []),
    {
      path: 'wallet',
      element: <LazyLoaded.WalletPage />,
    },

    {
      path: 'asset',
      element: <AssetDetailsPage />,
    },
    {
      path: 'transactions',
      element: <LazyLoaded.TransactionsPage />,
    },
    ...(jurisdictionId === JurisdictionsEnum.GE ||
    jurisdictionId === JurisdictionsEnum.EU
      ? [
          {
            path: 'dashboard-fees',
            element: <Navigate to="/dashboard-fees/deposit" replace />,
          },
          {
            path: 'dashboard-fees/:infoPath',
            element: <LazyLoaded.DashboardFeesPage />,
          },
          {
            path: 'support-info',
            element: <Navigate to="/support-info-page/general" replace />,
          },
          {
            path: 'support-info/:infoPath',
            element: <LazyLoaded.SupportInfoPage />,
          },
        ]
      : []),
    {
      path: 'account-information',
      element: <Navigate to="/account-information/personal" replace />,
    },
    {
      path: 'account-information/:infoPath',
      element: <LazyLoaded.AccountInformationPage />,
    },
    {
      path: 'more-pages',
      element: <LazyLoaded.MobileMorePage />,
    },
    {
      path: 'deposit',
      element: <LazyLoaded.DepositPage />,
    },
    {
      path: 'withdraw',
      element: <LazyLoaded.WithdrawalPage />,
    },
    {
      path: 'bog-card-and-payment/',
      element: <LazyLoaded.BogPaymentsMobile />,
    },
    ...(jurisdictionId === JurisdictionsEnum.UA ||
    process.env.ENVIRONMENT === Environment.dev
      ? [
          {
            path: 'swap-details',
            element: <SwapDetailsPage />,
          },
          {
            path: 'swap-task-details',
            element: <SwapTaskDetailsPage />,
          },
        ]
      : []),

    {
      path: 'convert',
      element: <Navigate to="BUY" replace />,
    },
    {
      path: 'convert/:type',
      element: <LazyLoaded.TradePage />,
    },

    ...(jurisdictionId === JurisdictionsEnum.EU
      ? [
          {
            path: 'ticket',
            element: <LazyLoaded.TicketPage />,
          },
        ]
      : []),

    {
      path: 'card-operation-result',
      element: <LazyLoaded.CardOperationResult />,
    },

    ...(process.env.ENVIRONMENT === Environment.dev
      ? [
          {
            path: 'notifications',
            element: <LazyLoaded.NotificationsPage />,
          },
        ]
      : []),
    ...(process.env.ENVIRONMENT === Environment.dev
      ? [
          {
            path: 'staking-calculator',
            element: <LazyLoaded.StakingCalculatorPage />,
          },
          {
            path: 'staking-preview',
            element: <LazyLoaded.StakingCalculationPreviewPage />,
          },
          { path: 'staking', element: <LazyLoaded.StakingPage /> },
          {
            path: 'staking-details/:id',
            element: <LazyLoaded.StakingDetailsPage />,
          },
          {
            path: 'early-redemption/:id',
            element: <LazyLoaded.EarlyRedemption />,
          },
        ]
      : []),
    {
      path: '/not-found',
      element: <PageNotFound />,
    },
    {
      path: '*',
      element: <Navigate to="/not-found" replace />,
    },
  ];

  return children;
};

const router = createBrowserRouter([
  // Dashboard
  {
    path: '/',
    element: <PrivateRoute component={<Dashboard />} />,
    children: getPrivateRoutesChildren(),
  },
  {
    path: 'activate-2FA',
    element: <PrivateRoute component={<ActivateTwoFaPage />} />,
  },
  {
    path: 'verify-email',
    element: <VerifyEmailPage />,
  },
  // { path: 'components', element: <CommonComponents /> },
  // { path: 'two-factor-auth/google', element: <CommonComponents /> },
  { path: 'login', element: <PublicRoute component={<LoginPage />} /> },
  {
    path: 'register',
    element: <PublicRoute component={<RegisterPage />} />,
  },
  {
    path: 'password-recovery',
    element: <PublicRoute component={<PasswordRecoveryPage />} />,
  },
  {
    path: 'authenticator-link',
    element: (
      <PublicRoute
        component={
          <OSNavigator
            androidLink={AUTHENTICATOR_LINK.googlePlay}
            iosLink={AUTHENTICATOR_LINK.appStore}
          />
        }
      />
    ),
  },

  {
    path: 'app-links',
    element: (
      <PublicRoute
        component={
          <OSNavigator
            androidLink={APP_LINKS.googlePlay}
            iosLink={APP_LINKS.appStore}
          />
        }
      />
    ),
  },

  //We don't use it anymore
  {
    path: 'password-reset/:resetToken',
    element: <PublicRoute component={<ResetPasswordPage />} />,
  },
  {
    path: 'two-factor-auth',
    element: <PublicRoute component={<TwoFaPage />} />,
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
