import { useTheme } from 'styled-components';

import { Input, StateSetter, Toggle, Typography } from '@libs/components';

import { useModal, useTranslation } from 'src/hooks';
import { BookIcon } from 'src/assets';

import {
  ChooseAddressWrapper,
  SaveAddressText,
  SelectAddressText,
  TrButton,
  Wrapper,
} from './styles';
import AddressOptionsContent, {
  AddressOptionsContentProps,
} from './components/AddressOptionsContent';
import NewAddressContent, {
  NewAddressContentProps,
} from './components/NewAddressContent';

type SaveOrChooseAddressProps = NewAddressContentProps &
  AddressOptionsContentProps & {
    saveText: string;
    checked: boolean;
    shouldToggleBeDisabled?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    optionsModalTitle?: string;
    saveAddressModalTitle?: string;
    rightComponent?: React.ReactNode | JSX.Element;
    valueValidator?: (value?: string) => boolean;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputErrorMessage?: string;
    setSaveAddressClicked?: StateSetter<boolean>;
    setAddressName?: (val: string) => void;
  };
const SaveOrChooseAddress: React.FC<SaveOrChooseAddressProps> = ({
  value,
  options,
  checked,
  saveText,
  buttonTitle,
  rightComponent,
  disclaimerText,
  disabled = false,
  selectedOptionId,
  inputPlaceholder,
  optionsModalTitle,
  saveAddressModalTitle,
  isValueInSavedAddresses,
  onOptionSelection,
  valueValidator,
  onButtonClick,
  setChecked,
  onChange,
  onBlur,
  inputErrorMessage,
  setSaveAddressClicked,
  shouldToggleBeDisabled,
  setAddressName,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { Dialog, openModal, closeModal } = useModal();
  const {
    Dialog: OptionsModal,
    openModal: openOptionsModal,
    closeModal: closeOptionModal,
  } = useModal();

  const openSaveAddressModal = () => {
    if (checked) {
      setChecked(false);
      setAddressName?.('');
    } else if (value.length > 0) {
      if (valueValidator) {
        const isValid = valueValidator(value);
        if (!isValid) return;
      }
      openModal();
      setSaveAddressClicked && setSaveAddressClicked(true);
    }
  };

  const handleSaveAddress = (addressName: string) => {
    closeModal();
    onButtonClick?.(addressName);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    onChange?.({
      ...e,
      target: {
        ...e.target,
        name,
        value: value.replace(/\s/g, ''),
      },
    });
  };

  const isAddressFromBook = !!options?.find((opt) => opt.title === value);

  const shouldEnableChoosing =
    (value.length < 1 || disabled) && options?.length;

  return (
    <Wrapper>
      <Input
        value={value}
        rightComponent={rightComponent}
        onBlur={onBlur}
        disabled={disabled}
        onChange={handleInputChange}
        placeholder={inputPlaceholder}
        errorMessage={inputErrorMessage}
      />
      <ChooseAddressWrapper>
        {shouldEnableChoosing || isAddressFromBook ? (
          <>
            <Typography type="caption">
              {t('withdraw_choose_address')}
            </Typography>
            <TrButton onClick={openOptionsModal}>
              <BookIcon />
              <SelectAddressText
                type="caption"
                fontFamily="primaryBold"
                color={colors.interactive.brandColor}
              >
                {optionsModalTitle}
              </SelectAddressText>
            </TrButton>
          </>
        ) : (
          <>
            <Toggle
              disabled={shouldToggleBeDisabled}
              labelSize="1.6rem"
              checked={checked}
              onChangeValue={openSaveAddressModal}
            />
            <SaveAddressText type="caption">{saveText}</SaveAddressText>
          </>
        )}
      </ChooseAddressWrapper>
      {!!options?.length && (
        <OptionsModal title={optionsModalTitle}>
          <AddressOptionsContent
            options={options}
            selectedOptionId={selectedOptionId}
            onOptionSelection={onOptionSelection}
            closeModal={closeOptionModal}
          />
        </OptionsModal>
      )}
      <Dialog title={saveAddressModalTitle}>
        <NewAddressContent
          value={value}
          buttonTitle={buttonTitle}
          disclaimerText={disclaimerText}
          inputPlaceholder={inputPlaceholder}
          isValueInSavedAddresses={isValueInSavedAddresses}
          onButtonClick={handleSaveAddress}
          setChecked={setChecked}
        />
      </Dialog>
    </Wrapper>
  );
};
export default SaveOrChooseAddress;
