import styled from 'styled-components';

import { Button, Table, ReferralRules, InfoCircleIcon } from '@libs/components';

import { ReferralSummary } from '../referral-balance/ReferralSummary';
import { ReferralIntroduceOffer } from '../referral-introduce-offer/ReferralIntroduceOffer';

export const BlueContainer = styled.div`
  width: 100%;

  background: linear-gradient(144.31deg, #3d62d7 -16.06%, #191c7a 121.84%);

  border-radius: ${({ theme }) => theme.borderRadius.highM};
`;

export const Glass = styled.div`
  position: relative;

  border: 0.01rem solid ${({ theme }) => `${theme.colors.text.interactive}4D`};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: 0px 4px 134px 0px #ffffff40 inset;
`;

export const ReferralRulesGlass = styled(Glass)`
  margin: 8.9rem 2.2rem 2.2rem;
  padding: 10rem 1.6rem 1.6rem;

  ${({ theme }) => theme.media.laptop`
      margin: 3.6rem;
      padding: 0 3.6rem;
  `}
`;

export const ReferralBalanceWrapper = styled.div`
  margin: 2.2rem 2.2rem 11.1rem;

  ${({ theme }) => theme.media.laptop`
      margin: 2rem 3.6rem 3.6rem;
  `}
`;

export const ReferralBalanceGlass = styled(Glass)`
  padding: 2.2rem 1.6rem 1.6rem;

  ${({ theme }) => theme.media.laptop`
      display: flex;
      justify-content: space-between;
      padding: 2.4rem;
      gap: 2rem;
  `}
`;

export const BalanceButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HistoryButton = styled(Button)`
  width: unset;
  height: unset;

  border-radius: 4.8rem;
  padding: 1rem 1.6rem;
`;

export const RulesWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const IconWrapper = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: #0000004d;
`;

export const CustomInfoCircleIcon = styled(InfoCircleIcon)`
  height: 2rem;
  width: 2rem; ;
`;

export const CustomTable = styled(Table)`
  thead {
    display: none;
  }

  tbody td:last-child {
    text-align: end !important;
  }
  td {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export const TableWrapper = styled.div`
  padding: 0 2.2rem;
  max-height: 65vh;
  overflow-x: hidden;

  ${({ theme }) => theme.media.laptop`
      padding: 0 2.4rem;
      width: 50rem;
      max-width: unset;

  `}
`;

export const CustomReferralRules = styled(ReferralRules)`
  ${({ theme }) => theme.media.laptop`
        display: flex;
        text-align: start;
        flex-direction: row-reverse;

        .usdtImg{
            position: unset;
        }
        .seeRulesButton {
            max-width: 47rem;
        }
        .textWrapper{
            text-align: start;
            max-width: 46rem;
        }
      
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const ClaimedBonusUsdtImg = styled.img`
  height: 5.3rem;
  transform: scaleX(-1);
`;

export const TotalClaimedBonusWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: 1.2rem;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  margin-bottom: 0.8rem;
  margin-top: 2.4rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomReferralSummary = styled(ReferralSummary)`
  display: flex;
  margin-top: 0;
  ${({ theme }) => theme.media.laptop` 
        display: none;
    `}
`;

export const CustomReferralIntroduceOffer = styled(ReferralIntroduceOffer)`
  margin: -8rem 2rem 2.2rem;

  display: flex;
  ${({ theme }) => theme.media.laptop` 
        display: none;
    `}
`;
