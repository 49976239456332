import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';

import { useApiResponse } from '@libs/hooks';
import { WalletInfoItem } from '@libs/types';
import {
  SelectModal,
  Typography,
  SelectData,
  Button,
  TableCell,
  RadioButton,
} from '@libs/components';
import { meQuery, FrStatuses } from '@libs/graphql';
import { FeatureModuleEnum, JurisdictionsEnum } from '@libs/backend-common';
import { filterData, formatBalance } from '@libs/helpers';

import { getCryptoAddressesByAssetQuery } from 'src/gql';
import {
  Disclaimer,
  FeatureRenderer,
  JurisdictionRenderer,
  SelectModalHeaderRenderOption,
} from 'src/components';
import {
  useModal,
  useTranslation,
  CurrencyOptionWithBalance,
  useCurrencyOptionsWithBalance,
  useJurisdictionDetector,
} from 'src/hooks';
import { DesertedIcon, SearchIcon } from 'src/assets';

import { useCreateCryptoAddress } from './hooks/useCreateCryptoAddress';
import { MultiAddressView } from './components/MultiAddressView';
import { NetworkArray, useNetworkSelect } from './hooks/useNetworkSelect';
import { AddressDetails } from './components/AddressDetails';
import {
  ValueWrapper,
  ValueContainer,
  DisclaimerCont,
  ValueTypography,
  CustomDisclaimer,
  CustomDepositAddress,
  AssetSelectContainer,
  NetworkSelectContainer,
  CustomDisclaimerContainer,
  CustomNetworkImgContainer,
  CustomSearchInput,
  LineBreaker,
  OptionContainer,
  OptionContainerRightCorner,
  CustomMegaPhoneIcon,
} from './style';

const searchInputKeyWords = ['description', 'name', 'label'];

export type AssetSelectType = SelectData<string>;
export type DepositAdressesProps = {
  initialAsset: WalletInfoItem;
};
const DepositAddresses: React.FC<DepositAdressesProps> = ({ initialAsset }) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentJurisdiction } = useJurisdictionDetector();

  const meRes = useQuery(meQuery);
  const { response: meResponse } = useApiResponse(meRes);
  const isUserVerified = meResponse?.me.frStatus === FrStatuses.VERIFIED;

  //search input
  const [searchValue, setSearchValue] = useState('');

  const [assetSearchValue, setAssetSerachValue] = useState('');
  const [networkSearchValue, setNetworkSerachValue] = useState('');

  const [selectedNetwork, setSelectedNetwork] = useState<NetworkArray>();
  const [selectedNetworkValue, setSelectedNetworkValue] = useState<string>();
  const handleNetworkSelect = useCallback((value: string | number) => {
    setSelectedNetworkValue(String(value));
  }, []);

  const currencyOptions = useCurrencyOptionsWithBalance();

  const addressRes = useQuery(getCryptoAddressesByAssetQuery, {
    skip: !selectedNetwork?.id || !initialAsset.code,
    fetchPolicy: 'network-only',
    variables: {
      record: {
        assetCode: String(initialAsset.code),
        networkId: Number(selectedNetwork?.id),
        term: undefined,
      },
    },
  });
  const { response: addressesRes } = useApiResponse(addressRes);

  const { getSupportedNetworks, networksForSelect } =
    useNetworkSelect(handleNetworkSelect);

  useEffect(() => {
    if (networksForSelect) {
      setSelectedNetwork(
        networksForSelect.find((opt) => opt.value === selectedNetworkValue)
      );
    }
  }, [networksForSelect, selectedNetworkValue]);

  const handleGenerationSucess = () => {
    if (isMultiAddressAllowed) openNewAddressModal();
    addressRes.refetch();
  };

  const { createCryptoAddress, isCreatingAddress, generatedAddressId } =
    useCreateCryptoAddress(handleGenerationSucess);

  useEffect(() => {
    getSupportedNetworks(initialAsset.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAsset.code]);

  const handleGenerateNetwork = () => {
    if (
      selectedNetwork?.value &&
      (isUserVerified || currentJurisdiction === JurisdictionsEnum.UA)
    ) {
      setSelectedNetworkValue(selectedNetwork.value);
      createCryptoAddress(selectedNetwork.value);
    } else if (
      selectedNetwork?.value &&
      !isUserVerified &&
      currentJurisdiction !== JurisdictionsEnum.UA
    ) {
      navigate('/kyc', { replace: true });
    }
  };

  const showNetworkSelect = !!(
    networksForSelect && networksForSelect.length > 1
  );

  const selectedAsset = useMemo(
    () =>
      currencyOptions.cryptoWithNetwork.find(
        (opt) => opt.value === initialAsset.code
      ),
    [currencyOptions, initialAsset.code]
  );

  const addresses = addressesRes?.getCryptoAddressesByAsset.addresses;

  const isMultiAddressAllowed =
    addressesRes?.getCryptoAddressesByAsset.isMultiCryptoAddressesEnabled;

  const filtereAssets = useMemo(() => {
    return filterData(addresses, searchValue, ['address', 'name']);
  }, [addresses, searchValue]);

  const {
    Dialog: NewAddressDialog,
    openModal: openNewAddressModal,
    closeModal: closeNewAddressModal,
  } = useModal();

  const assetHeaderRenderOption = (closeSelect: () => void) => {
    return (
      <SelectModalHeaderRenderOption
        selectOptionsLabel={t('filter_select_asset_placeholder')}
        searchInputValue={assetSearchValue}
        setSearchInputValue={setAssetSerachValue}
        closeSelect={closeSelect}
      />
    );
  };

  const handleSelectedAssetValue = useCallback(
    (val: string | number) => {
      navigate(`/deposit?selectedCurrency=${val}`);
    },
    [navigate]
  );

  const renderOption = useCallback(
    (item: CurrencyOptionWithBalance, index: number) => {
      const isSelected = item.value === initialAsset.code;
      const handleClick = () => {
        handleSelectedAssetValue(item.value);
      };

      const usdAmount = formatBalance(Number(item.rate));

      return (
        <OptionContainer
          data-cy={`option-${item?.label}`}
          key={index}
          onClick={handleClick}
        >
          <TableCell
            title={item.name}
            img={item.icon}
            description={String(item.value)}
          />
          <OptionContainerRightCorner>
            <TableCell
              justifyToLeft={false}
              title={`${item.amount} ${item.value}`}
              description={t('common_asset_rate', { amount: usdAmount })}
            />
            <RadioButton checked={isSelected} />
          </OptionContainerRightCorner>
        </OptionContainer>
      );
    },
    [handleSelectedAssetValue, initialAsset.code, t]
  );

  const networkHeaderRenderOption = (closeSelect: () => void) => {
    return (
      <SelectModalHeaderRenderOption
        selectOptionsLabel={t('select_network')}
        searchInputValue={networkSearchValue}
        setSearchInputValue={setNetworkSerachValue}
        closeSelect={closeSelect}
      />
    );
  };

  const renderValue = useCallback(
    (val?: SelectData<string>) => (
      <ValueContainer>
        {val?.icon && (
          <CustomNetworkImgContainer>{val.icon}</CustomNetworkImgContainer>
        )}
        <ValueWrapper>
          <ValueTypography
            type="caption"
            fontFamily="primaryMedium"
            fontSize={1.2}
            lineHeight={2.4}
            color={colors.text.secondary}
          >
            {t('select_network')}
          </ValueTypography>
          <Typography type="bodyButton" fontFamily="primaryMedium">
            {val?.label}
          </Typography>
        </ValueWrapper>
      </ValueContainer>
    ),
    [colors, t]
  );

  const assetRenderValue = useCallback(
    (val?: SelectData<string>) => (
      <ValueContainer>
        {val?.icon && (
          <CustomNetworkImgContainer>{val.icon}</CustomNetworkImgContainer>
        )}
        <ValueWrapper>
          <ValueTypography
            type="caption"
            fontFamily="primaryMedium"
            fontSize={1.2}
            lineHeight={2.4}
            color={colors.text.secondary}
          >
            {t('select_asset')}
          </ValueTypography>
          <Typography type="bodyButton" fontFamily="primaryMedium">
            {val?.label}
          </Typography>
        </ValueWrapper>
      </ValueContainer>
    ),
    [colors, t]
  );

  return (
    <FeatureRenderer
      shouldCheckKyc={false}
      acceptedFeature={[FeatureModuleEnum.CRYPTO_DEPOSIT]}
    >
      <CustomDepositAddress
        data-testid="depositAddressContainer"
        data-cy="depositAddressContainer"
      >
        <AssetSelectContainer>
          <SelectModal
            renderOption={renderOption}
            searchInputValue={assetSearchValue}
            renderOptionHeader={assetHeaderRenderOption}
            options={currencyOptions.cryptoWithNetwork}
            placeholder={t('select_asset')}
            textAfterSelection={t('selected_asset')}
            selectedValue={initialAsset.code}
            onChange={handleSelectedAssetValue}
            renderValue={assetRenderValue}
            searchKeyWord={searchInputKeyWords}
            emptyStateTitle={t('landing_fees_asset_not_found')}
            emptyStateImage={DesertedIcon}
          />
          {isMultiAddressAllowed && addresses && addresses.length > 1 && (
            <>
              <LineBreaker />
              <CustomSearchInput
                placeholder={t('common_search')}
                value={searchValue}
                onChangeText={setSearchValue}
                icon={<SearchIcon />}
              />
            </>
          )}
        </AssetSelectContainer>
        {showNetworkSelect && (
          <NetworkSelectContainer data-testid="networkSelectContainer">
            <SelectModal
              searchInputValue={networkSearchValue}
              renderOptionHeader={networkHeaderRenderOption}
              options={networksForSelect}
              renderValue={renderValue}
              selectedValue={selectedNetworkValue}
              onChange={handleNetworkSelect}
              title={t('select_network')}
              searchKeyWord={searchInputKeyWords}
              emptyStateTitle={t('common_not_found')}
              emptyStateImage={DesertedIcon}
            />
          </NetworkSelectContainer>
        )}

        {isMultiAddressAllowed && !!addresses?.length && (
          <MultiAddressView
            loading={!addressesRes?.getCryptoAddressesByAsset}
            networkId={Number(selectedNetwork?.id)}
            assetCode={String(initialAsset.code)}
            addresses={filtereAssets}
            handleGenerateNetwork={handleGenerateNetwork}
            isCreatingAddress={isCreatingAddress}
            NewAddressDialog={NewAddressDialog}
            closeNewAddressModal={closeNewAddressModal}
            generatedAddressId={generatedAddressId}
          />
        )}
        <JurisdictionRenderer
          acceptedJurisdictions={[JurisdictionsEnum.EU, JurisdictionsEnum.GE]}
        >
          {!isUserVerified && (
            <DisclaimerCont>
              <Disclaimer description={t('kyc_operation_disclaimer')} />
            </DisclaimerCont>
          )}
        </JurisdictionRenderer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
          <DisclaimerCont>
            <Disclaimer description={t('deposit_crypto_disclaimer')} />
          </DisclaimerCont>
        </JurisdictionRenderer>
        {!isMultiAddressAllowed &&
          !!addresses?.length &&
          !!selectedAsset?.label &&
          selectedNetwork?.label && (
            <>
              <DisclaimerCont data-testid="disclaimerCont">
                <Disclaimer
                  description={t('disclaimer_only_deposit', {
                    name: selectedAsset.label,
                  })}
                  boldDescription={t('disclaimer_make_sure', {
                    network: selectedNetwork.label,
                  })}
                />
              </DisclaimerCont>
              <AddressDetails
                address={addresses[0].address}
                isBnbMemo={selectedAsset.isMemoRequired}
                bnbMemoAddress={addresses[0].tag}
              />
            </>
          )}

        {addresses?.length === 0 && selectedNetwork?.label && (
          <CustomDisclaimerContainer data-testid="customDisclaimerCont">
            <CustomDisclaimer>
              <Disclaimer
                title={t('disclaimer_no_wallet_address_yet')}
                description={t('disclaimer_no_address', {
                  name: selectedNetwork.label,
                })}
                icon={<CustomMegaPhoneIcon />}
              />
            </CustomDisclaimer>
            <Button
              onClick={handleGenerateNetwork}
              title={t('generate_address')}
              isLoading={isCreatingAddress}
            />
          </CustomDisclaimerContainer>
        )}
      </CustomDepositAddress>
    </FeatureRenderer>
  );
};
export default DepositAddresses;
